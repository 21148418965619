import React from "react";
import ThemeProvider from "./src/components/ThemeProvider";

// Add a script to ensure theme consistency during client-side navigation
export const onClientEntry = () => {
  // This runs when the Gatsby app first starts in the browser
  if (typeof window !== "undefined") {
    // Ensure the theme is consistent during client-side navigation
    const colorMode = localStorage.getItem("odyssey-color-scheme");
    if (colorMode === '"light"') {
      document.documentElement.setAttribute("data-joy-color-scheme", "light");
      document.documentElement.style.colorScheme = "light";
      // Add light mode class
      document.documentElement.classList.remove("dark-mode");
      document.documentElement.classList.add("light-mode");
    } else {
      document.documentElement.setAttribute("data-joy-color-scheme", "dark");
      document.documentElement.style.colorScheme = "dark";
      // Add dark mode class
      document.documentElement.classList.add("dark-mode");
      document.documentElement.classList.remove("light-mode");
    }
  }
};

// Wraps Gatsby's root element with the Material UI theme provider
export const wrapRootElement = ({ element }: any) => {
  return <ThemeProvider>{element}</ThemeProvider>;
};
