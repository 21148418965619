import { extendTheme } from "@mui/joy/styles";
import type { Theme, ThemeVars } from "@mui/joy/styles";
import type { TypographySystem } from "@mui/joy/styles";

// For the first error, add a type extension for the components property
// Add this near the top of the file, after the imports
declare module "@mui/joy/styles" {
  interface Components {
    // This allows accessing components in a more flexible way
    [key: string]: any;
  }

  interface Theme {
    components?: Components;
  }

  interface PaletteBackground {
    teamPhoto?: string;
  }
}

// Convert the Tailwind config to a Joy UI theme
const joyTheme = {
  colorSchemes: {
    light: {
      palette: {
        primary: {
          50: "#e3f2fd",
          100: "#bbdefb",
          200: "#90caf9",
          300: "#64b5f6",
          400: "#42a5f5",
          500: "#4dabf7", // primary.DEFAULT from Tailwind
          600: "#1e88e5",
          700: "#1976d2",
          800: "#1565c0",
          900: "#0d47a1",
        },
        neutral: {
          50: "#fafafa",
          100: "#f5f5f5",
          200: "#eeeeee",
          300: "#e0e0e0",
          400: "#bdbdbd",
          500: "#9e9e9e",
          600: "#757575",
          700: "#616161",
          800: "#424242",
          900: "#212121",
        },
        background: {
          body: "#ffffff",
          surface: "#f8f9fa",
          popup: "#ffffff",
          level1: "#f5f5f5",
          level2: "#eeeeee",
          level3: "#e0e0e0",
        },
        text: {
          primary: "#212121",
          secondary: "#616161",
          tertiary: "#9e9e9e",
        },
        common: {
          black: "#000000",
          white: "#ffffff",
        },
      },
    },
    dark: {
      palette: {
        primary: {
          50: "#e3f2fd",
          100: "#bbdefb",
          200: "#90caf9",
          300: "#74c0fc", // primary.light from Tailwind
          400: "#5bb6f9",
          500: "#4dabf7", // primary.DEFAULT from Tailwind
          600: "#3b8ac4", // primary.dark from Tailwind
          700: "#2979ff",
          800: "#2962ff",
          900: "#1e54b7",
        },
        neutral: {
          50: "#f9fafb",
          100: "#f3f4f6",
          200: "#e5e7eb",
          300: "#d1d5db",
          400: "#9ca3af",
          500: "#6b7280",
          600: "#4b5563",
          700: "#374151",
          800: "#1f2937", // gray.800 from Tailwind
          900: "#111827",
        },
        background: {
          body: "#121212", // background.DEFAULT from Tailwind
          surface: "#1e1e1e", // background.light from Tailwind
          popup: "#1e1e1e",
          level1: "#1e1e1e",
          level2: "#2d2d2d",
          level3: "#333333", // border from Tailwind
        },
        common: {
          black: "#000000",
          white: "#ffffff",
        },
      },
    },
  },
  fontFamily: {
    body: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      '"Open Sans"',
      '"Helvetica Neue"',
      "sans-serif",
    ].join(","),
    display: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      '"Open Sans"',
      '"Helvetica Neue"',
      "sans-serif",
    ].join(","),
  },
  fontSize: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem", // base from Tailwind
    lg: "1.125rem",
    xl: "1.25rem", // xl from Tailwind
    xl2: "1.5rem",
    xl3: "1.875rem",
    xl4: "2.25rem", // 4xl from Tailwind
  },
  fontWeight: {
    md: 400, // normal from Tailwind
    lg: 500,
    xl: 700, // bold from Tailwind
  },
  radius: {
    xs: "0.125rem", // sm from Tailwind
    sm: "0.25rem", // default from Tailwind
    md: "0.375rem", // md from Tailwind
    lg: "0.5rem", // lg from Tailwind
    xl: "0.75rem",
  },
  shadow: {
    xs: "none",
    sm: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
    md: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    lg: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
    xl: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
    inset: "inset 0 2px 4px 0 rgba(0, 0, 0, 0.05)",
  },
  components: {
    JoyButton: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          borderRadius: theme.vars.radius.sm,
          transition: "all 0.2s",
          "&:hover": {
            boxShadow: theme.shadow.md,
          },
        }),
      },
      defaultProps: {
        size: "md",
        color: "primary",
        variant: "solid",
      },
    },
    JoyCard: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => {
          const isLight = theme.palette.mode === "light";
          return {
            backgroundColor: theme.vars.palette.background.surface,
            borderColor: isLight
              ? theme.vars.palette.neutral[300]
              : theme.vars.palette.neutral[800],
            transition: "all 0.2s",
            "&:hover": {
              borderColor: theme.vars.palette.primary[500],
              boxShadow: theme.shadow.lg,
            },
          };
        },
      },
    },
    JoyAspectRatio: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          ".MuiCard-root &": {
            backgroundColor: theme.vars.palette.background.teamPhoto,
            borderRadius: theme.vars.radius.md,
            overflow: "hidden",
          },
        }),
      },
    },
    JoyTypography: {
      styleOverrides: {
        h1: ({ theme }: { theme: Theme }) => ({
          fontSize: theme.fontSize.xl4,
          fontWeight: theme.fontWeight.xl,
        }),
        h2: ({ theme }: { theme: Theme }) => ({
          fontSize: theme.fontSize.xl3,
          fontWeight: theme.fontWeight.xl,
        }),
        h3: ({ theme }: { theme: Theme }) => ({
          fontSize: theme.fontSize.xl,
          fontWeight: theme.fontWeight.xl,
        }),
        body1: ({ theme }: { theme: Theme }) => ({
          fontSize: theme.fontSize.md,
        }),
      },
    },
    JoyModal: {
      styleOverrides: {
        backdrop: ({ theme }: { theme: Theme }) => ({
          backgroundColor:
            theme.palette.mode === "light"
              ? "rgba(0, 0, 0, 0.5)"
              : "rgba(0, 0, 0, 0.8)",
        }),
      },
    },
    JoyIconButton: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          transition: "all 0.2s",
          "&:hover": {
            transform: "scale(1.05)",
          },
        }),
      },
    },
  },
};

// For the third error, use a type assertion when calling extendTheme
// Replace the line:
// const theme = extendTheme(joyTheme);
// with:
const theme = extendTheme(joyTheme as any);

// Alternatively, you can be more specific with the type assertion:
// const theme = extendTheme(joyTheme as CssVarsThemeOptions);

// Add custom breakpoints that match Tailwind
// Use the extendTheme function to properly set breakpoints
const themeWithBreakpoints = {
  ...theme,
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
};

export default extendTheme(themeWithBreakpoints);
