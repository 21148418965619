exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-en-demo-tsx": () => import("./../../../src/pages/en/demo.tsx" /* webpackChunkName: "component---src-pages-en-demo-tsx" */),
  "component---src-pages-en-demo-video-tsx": () => import("./../../../src/pages/en/demoVideo.tsx" /* webpackChunkName: "component---src-pages-en-demo-video-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jp-demo-tsx": () => import("./../../../src/pages/jp/demo.tsx" /* webpackChunkName: "component---src-pages-jp-demo-tsx" */),
  "component---src-pages-jp-demo-video-tsx": () => import("./../../../src/pages/jp/demoVideo.tsx" /* webpackChunkName: "component---src-pages-jp-demo-video-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-privacy-pages-tsx": () => import("./../../../src/templates/privacy-pages.tsx" /* webpackChunkName: "component---src-templates-privacy-pages-tsx" */)
}

