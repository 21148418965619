import React, { useEffect, useState } from "react";
import {
  CssVarsProvider,
  useColorScheme as useJoyColorScheme,
} from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import theme from "../styles/theme";

interface ThemeProviderProps {
  children: React.ReactNode;
}

// Custom wrapper to handle theme class updates
const ThemeClassUpdater: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { mode } = useJoyColorScheme();

  useEffect(() => {
    if (typeof document !== "undefined") {
      if (mode === "light") {
        document.documentElement.classList.remove("dark-mode");
        document.documentElement.classList.add("light-mode");
      } else {
        document.documentElement.classList.add("dark-mode");
        document.documentElement.classList.remove("light-mode");
      }
    }
  }, [mode]);

  return <>{children}</>;
};

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  // Use state to track if we're in the browser
  const [isMounted, setIsMounted] = useState(false);

  // Only run after component mounts (client-side only)
  useEffect(() => {
    // Mark as mounted
    setIsMounted(true);

    // Add a class to the body once mounted to enable transitions
    if (typeof document !== "undefined") {
      // Ensure transitions are disabled initially to prevent flashing
      document.documentElement.classList.add("disable-transitions");

      // Enable transitions after a short delay
      setTimeout(() => {
        document.documentElement.classList.remove("disable-transitions");
        document.documentElement.classList.add("enable-transitions");
      }, 100);
    }

    // Cleanup function
    return () => {
      if (typeof document !== "undefined") {
        document.documentElement.classList.remove("enable-transitions");
      }
    };
  }, []);

  return (
    <>
      {/* Add global styles for theme transition */}
      <style
        dangerouslySetInnerHTML={{
          __html: `
          /* Initially disable all transitions */
          .disable-transitions * {
            transition: none !important;
          }
          
          /* Enable smooth transitions after initial render */
          .enable-transitions * {
            transition: background-color 0.3s ease, 
                        color 0.3s ease,
                        border-color 0.3s ease,
                        box-shadow 0.3s ease !important;
          }
        `,
        }}
      />
      <CssVarsProvider
        theme={theme}
        defaultMode="dark"
        modeStorageKey="odyssey-color-scheme"
        disableNestedContext
      >
        <CssBaseline />
        <ThemeClassUpdater>{children}</ThemeClassUpdater>
      </CssVarsProvider>
    </>
  );
};

export default ThemeProvider;
